@import 'scss/_index.scss';

.ItemIcon {
  width: $spacing-lg;
  border: none;
}

.ItemIconSmall {
  width: calc(#{$spacing-md * 1.2});
  border: none;
}
