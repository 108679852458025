@import 'scss/_index.scss';

.NavbarWrapper {
  width: 100%;
  background: $white;
  padding: $spacing-md $spacing-lg;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NavbarBrand {
  display: flex;
  justify-content: flex-end;
}

.NavbarItem {
  width: 10em;
  margin: 0 $spacing-sm;
  align-self: center;
}

.NavbarName {
  padding-left: $spacing-sm;
  color: $charCoal;
  font-size: $font-size-xs;
}

.NavbarSelectItem {
  color: $primary;
}

.NavbarAvatarWrapper {
  position: relative;
}

.NavbarAvatarMenuList {
  position: absolute;
  background: $white;
  padding: $spacing-xs 0;
  border: 1px solid $palegray;
  top: calc(100% + 5px);
  border-radius: $border-radius-basic;
  width: 100%;
  cursor: pointer;
  display: none;

  &__opened {
    display: block;
  }
}

.NavbarAvatar {
  cursor: pointer;
  border: none;
  padding: 0;
  background: $white;
  outline: none;
}

.NavbarAvatarMenuItem {
  font-size: $font-size-xs;
  color: $gray;
  padding: $spacing-xs $spacing-sm;
  width: 100%;
  text-align: left;

  &:hover {
    background-color: $lightgray;
  }
  &:disabled {
    cursor: not-allowed;
    background: $white;
    color: $palegray;
  }
}
