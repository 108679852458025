@import 'scss/_index.scss';

$modal-action-buttons-spacing: 2.5em;

.SetAccountLabel {
  line-height: calc(#{$spacing-md * 3.5});
}

.SetAccountPayments {
  margin-bottom: $spacing-xl;
  font-size: $font-size-lg;
  font-weight: 600;
  color: $lightblack;
}

.SetAccountSpacer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 450px;
}

.SetAccountMessage {
  margin-left: $spacing-sm;
  color: $charCoal;
  font-size: $font-size-md;
  margin-bottom: 2px;
}

.SetAccountLabelWrapper {
  display: flex;
  align-items: center;
}

.SetAccountItemWrapper {
  margin-bottom: calc(#{$spacing-md * 1.25});
}

.SetAccountPaymentButtonsWrapper {
  padding: $spacing-lg $modal-action-buttons-spacing
    $modal-action-buttons-spacing $modal-action-buttons-spacing;
  margin: $spacing-lg -2.5em -2.5em -2.5em;
  background-color: $colorPrimaryLight;
}
