@import 'scss/_index.scss';

.UserFiltersWrapper {
  padding-bottom: $spacing-lg;
  border-bottom: 1px solid $dimmed;
}

.UserFilterWrapper {
  min-width: 146px;
  margin-right: $spacing-lg;
}
