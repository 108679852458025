@import 'scss/_index.scss';
@import 'sprite.scss';
// rgba is needed for the selected flag hover state to blend in with
// the border-highlighting some browsers give the input on focus
$hoverColor: none;
$greyText: #999 !default;
$greyBorder: $dimmed !default;

$flagHeight: 15px !default;
$flagWidth: 20px !default;
$flagPadding: 8px !default;
// this border width is used for the popup and divider, but it is also
// assumed to be the border width of the input, which we do not control
$borderWidth: 1px !default;

$arrowHeight: 4px !default;
$arrowWidth: 6px !default;
$triangleBorder: 3px !default;
$arrowPadding: 6px !default;
$arrowColor: $greyText !default;

$inputPadding: 6px !default;
$selectedFlagWidth: $flagWidth + (2 * $flagPadding) !default;
$selectedFlagArrowWidth: $flagWidth + $flagPadding + $arrowWidth +
  (2 * $arrowPadding) !default;
$selectedFlagArrowDialCodeWidth: $selectedFlagArrowWidth + $flagPadding !default;

// enough space for them to click off to close
$mobilePopupMargin: 30px;
$intl-tel-input-sprite-path: '../../../node_modules/react-intl-tel-input/dist/flags.png' !default;
$intl-tel-input-sprite-2x-path: '../../../node_modules/react-intl-tel-input/dist/flags@2x.png' !default;

.intl-tel-input {
  // need position on the container so the selected flag can be
  // absolutely positioned over the input
  position: relative;
  // keep the input's default inline properties
  display: inline-block;
  width: 100%;

  // paul irish says this is ok
  // http://www.paulirish.com/2012/box-sizing-border-box-ftw/
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .hide {
    display: none;
  }
  // need this during init, to get the height of the dropdown
  .v-hide {
    visibility: hidden;
  }

  // specify types to increase specificity e.g. to override bootstrap v2.3
  input,
  input[type='text'],
  input[type='tel'] {
    position: relative;
    // input is bottom level, below selected flag and dropdown
    z-index: 0;

    // any vertical margin the user has on their inputs would no longer work as expected
    // because we wrap everything in a container div. i justify the use of !important
    // here because i don't think the user should ever have vertical margin here - when
    // the input is wrapped in a container, vertical margin messes up alignment with other
    // inline elements (e.g. an adjacent button) in firefox, and probably other browsers.
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    // make space for the selected flag
    // Note: no !important here, as the user may want to tweak this so that the
    // perceived input padding matches their existing styles
    padding-right: $selectedFlagWidth;

    // any margin-right here will push the selected-flag away
    margin-right: 0;
  }

  .flag-container {
    // positioned over the top of the input
    position: absolute;
    // full height
    top: 0;
    bottom: 0;
    right: 0;
    // prevent the highlighted child from overlapping the input border
    padding: $borderWidth;
  }

  .selected-flag {
    // render above the input
    z-index: 1;
    position: relative;
    width: $selectedFlagWidth;
    // this must be full-height both for the hover highlight, and to push down the
    // dropdown so it appears below the input
    height: 100%;
    padding: 0 0 0 $flagPadding;
    &:focus {
      outline: none;
    }
    // vertically center the flag
    .iti-flag {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    .iti-arrow {
      position: absolute;
      // split the difference between the flag and the arrow height to verically center
      top: 50%;
      margin-top: -1 * ($arrowHeight / 2);
      right: $arrowPadding;

      // css triangle
      width: 0;
      height: 0;
      border-left: $triangleBorder solid transparent;
      border-right: $triangleBorder solid transparent;
      border-top: $arrowHeight solid $arrowColor;

      &.up {
        border-top: none;
        border-bottom: $arrowHeight solid $arrowColor;
      }
    }
  }

  // the dropdown
  .country-list {
    position: absolute;
    // popup so render above everything else
    background: $primary;

    // override default list styles
    list-style: none;
    // in case any container has text-align:center
    text-align: left;

    // place menu above the input element
    &.dropup {
      bottom: 100%;
      margin-bottom: (-$borderWidth);
    }

    // dropdown flags need consistent width, so wrap in a container
    .flag-box {
      display: inline-block;
      width: $flagWidth;
    }

    padding: 0;
    // margin-left to compensate for the padding on the parent
    margin: 0 0 0 (-$borderWidth);

    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);

    border: $borderWidth solid $greyBorder;

    // don't let the contents wrap AKA the container will be as wide as the contents
    white-space: nowrap;
    // except on small screens, where we force the dropdown width to match the input
    @media (max-width: 500px) {
      white-space: normal;
    }

    max-height: 200px;

    overflow-y: scroll;

    -webkit-overflow-scrolling: touch;

    // the divider below the preferred countries
    .divider {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: $borderWidth solid $greyBorder;
    }

    // each country item in dropdown (we must have separate class to differentiate from dividers)
    .country {
      // Note: decided not to use line-height here for alignment because it causes issues e.g. large font-sizes will overlap, and also looks bad if one country overflows onto 2 lines
      padding: 5px 10px;
      // the dial codes after the country names are greyed out
      .dial-code {
        color: $light;
      }
    }
    .country.highlight {
      background-color: rgba(255, 255, 255, 0.1);
    }

    // spacing between country flag, name and dial code
    .flag-box,
    .country-name,
    .dial-code {
      vertical-align: middle;
    }
    .flag-box,
    .country-name {
      margin-right: 6px;
    }
    .country-name {
      color: $white;
    }
  }

  .country-list::-webkit-scrollbar {
    background: rgba(255, 255, 255, 0.1);
  }

  .country-list::-webkit-scrollbar-thumb {
    background: $light;
    height: 2em;
    border-radius: 0.3em;
  }

  &.allow-dropdown {
    input,
    input[type='text'],
    input[type='tel'] {
      padding-right: $inputPadding;
      padding-left: $selectedFlagArrowWidth + $inputPadding;
      margin-left: 0;
    }
    .flag-container {
      right: auto;
      left: 0;
      width: 100%;
    }
    .selected-flag {
      width: $selectedFlagArrowWidth;
    }

    // hover state - show flag is clickable
    .flag-container:hover {
      cursor: pointer;
      .selected-flag {
        background-color: $hoverColor;
      }
    }
    // disable hover state when input is disabled
    input[disabled] + .flag-container:hover,
    input[readonly] + .flag-container:hover {
      cursor: default;
      .selected-flag {
        background-color: transparent;
      }
    }

    &.separate-dial-code {
      .selected-flag {
        // now that we have digits in this section, it needs this visual separation
        background-color: $hoverColor;
        // for vertical centering
        display: table;
      }
      .selected-dial-code {
        // for vertical centering
        display: table-cell;
        vertical-align: middle;
        color: white;

        padding-left: $flagWidth + $flagPadding;
      }

      // .iti-sdc is for Separate Dial Code, with lengths from 2-5 because shortest is "+1", longest is "+1684"
      $charLength: 8px;
      @for $i from 2 through 5 {
        &.iti-sdc-#{$i} {
          input,
          input[type='text'],
          input[type='tel'] {
            padding-left: $selectedFlagArrowDialCodeWidth +
              $inputPadding +
              ($i * $charLength);
          }
          .selected-flag {
            width: $selectedFlagArrowDialCodeWidth + ($i * $charLength);
          }
        }
      }
    }
  }

  // if dropdownContainer option is set, increase z-index to prevent display issues
  &.iti-container {
    position: absolute;
    top: -1000px;
    left: -1000px;
    // higher than default Bootstrap modal z-index of 1050
    z-index: 1060;
    // to keep styling consistent with .flag-container
    padding: $borderWidth;
    &:hover {
      cursor: pointer;
    }
  }
}

// overrides for mobile popup
.iti-mobile .intl-tel-input {
  &.iti-container {
    top: $mobilePopupMargin;
    bottom: $mobilePopupMargin;
    left: $mobilePopupMargin;
    right: $mobilePopupMargin;
    position: fixed;
  }
  .country-list {
    max-height: 100%;
    width: 100%;
    background: $primary;
    -webkit-overflow-scrolling: touch;
    .country {
      padding: 10px 10px;
      // increase line height because dropdown copy is v likely to overflow on mobile and when it does it needs to be well spaced
      line-height: 1.5em;
    }
  }
}

.iti-flag {
  width: $flagWidth;
  height: $flagHeight;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url($intl-tel-input-sprite-path);
  background-repeat: no-repeat;
  // empty state
  background-color: #dbdbdb;
  background-position: $flagWidth 0;

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2 / 1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
  }
}

// hack for Nepal which is the only flag that is not square/rectangle, so it has transparency, so you can see the default grey behind it
.iti-flag.np {
  background-color: transparent;
}

.input-tel-tel {
  box-sizing: border-box;
  border: 1px solid $dimmed;
  height: 3em;
  width: 100%;
  padding-left: 1.2em;
  color: $white;
  background: $primary;
  font-size: 1em;
  &.notValid {
    border-color: red;
  }
}

.input-tel-white {
  color: $mousegray;
  background: $white;
  width: 100%;
  height: 38px;
  font-size: $font-size-sm;
  &:focus {
    outline: none;
  }
}

.country-list .input-tel-white {
  background: $white;
}

.input-tel-tel::placeholder {
  color: $light;
}

.input-tel-white::placeholder {
  color: $palegray;
}

.input-tel-tel:focus {
  outline: none;
}

.intl-tel-input-white {
  // need position on the container so the selected flag can be
  // absolutely positioned over the input
  position: relative;
  // keep the input's default inline properties
  display: inline-block;
  width: 100%;

  // paul irish says this is ok
  // http://www.paulirish.com/2012/box-sizing-border-box-ftw/
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .hide {
    display: none;
  }
  // need this during init, to get the height of the dropdown
  .v-hide {
    visibility: hidden;
  }

  // specify types to increase specificity e.g. to override bootstrap v2.3
  input,
  input[type='text'],
  input[type='tel'] {
    position: relative;
    // input is bottom level, below selected flag and dropdown
    z-index: 0;

    // any vertical margin the user has on their inputs would no longer work as expected
    // because we wrap everything in a container div. i justify the use of !important
    // here because i don't think the user should ever have vertical margin here - when
    // the input is wrapped in a container, vertical margin messes up alignment with other
    // inline elements (e.g. an adjacent button) in firefox, and probably other browsers.
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    // make space for the selected flag
    // Note: no !important here, as the user may want to tweak this so that the
    // perceived input padding matches their existing styles
    padding-right: $selectedFlagWidth;

    // any margin-right here will push the selected-flag away
    margin-right: 0;
  }

  .flag-container {
    // positioned over the top of the input
    position: absolute;
    // full height
    top: 0;
    bottom: 0;
    right: 0;
    // prevent the highlighted child from overlapping the input border
    padding: $borderWidth;
  }

  .selected-flag {
    // render above the input
    z-index: 1;
    position: relative;
    width: $selectedFlagWidth;
    // this must be full-height both for the hover highlight, and to push down the
    // dropdown so it appears below the input
    height: 100%;
    padding: 0 0 0 $flagPadding;
    &:focus {
      outline: none;
    }
    // vertically center the flag
    .iti-flag {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    .iti-arrow {
      position: absolute;
      // split the difference between the flag and the arrow height to verically center
      top: 50%;
      margin-top: -1 * ($arrowHeight / 2);
      right: $arrowPadding;

      // css triangle
      width: 0;
      height: 0;
      border-left: $triangleBorder solid transparent;
      border-right: $triangleBorder solid transparent;
      border-top: $arrowHeight solid $arrowColor;

      &.up {
        border-top: none;
        border-bottom: $arrowHeight solid $arrowColor;
      }
    }
  }

  // the dropdown
  .country-list {
    position: absolute;
    // popup so render above everything else
    background: $white;
    color: $gray;

    // override default list styles
    list-style: none;
    // in case any container has text-align:center
    text-align: left;

    // place menu above the input element
    &.dropup {
      bottom: 100%;
      margin-bottom: (-$borderWidth);
    }

    // dropdown flags need consistent width, so wrap in a container
    .flag-box {
      display: inline-block;
      width: $flagWidth;
    }

    padding: 0;
    // margin-left to compensate for the padding on the parent
    margin: 0 0 0 (-$borderWidth);

    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);

    border: $borderWidth solid $greyBorder;

    // don't let the contents wrap AKA the container will be as wide as the contents
    white-space: nowrap;
    // except on small screens, where we force the dropdown width to match the input
    @media (max-width: 500px) {
      white-space: normal;
    }

    max-height: 200px;

    overflow-y: scroll;

    -webkit-overflow-scrolling: touch;

    // the divider below the preferred countries
    .divider {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: $borderWidth solid $greyBorder;
    }

    // each country item in dropdown (we must have separate class to differentiate from dividers)
    .country {
      // Note: decided not to use line-height here for alignment because it causes issues e.g. large font-sizes will overlap, and also looks bad if one country overflows onto 2 lines
      padding: 5px 10px;
      // the dial codes after the country names are greyed out
      .dial-code {
        color: $light;
      }
    }
    .country.highlight {
      background-color: rgba(255, 255, 255, 0.1);
    }

    // spacing between country flag, name and dial code
    .flag-box,
    .country-name,
    .dial-code {
      vertical-align: middle;
    }
    .flag-box,
    .country-name {
      margin-right: 6px;
    }
    .country-name {
      color: $gray;
      font-size: $font-size-sm;
    }
  }

  .country-list::-webkit-scrollbar {
    background: rgba(255, 255, 255, 0.1);
  }

  .country-list::-webkit-scrollbar-thumb {
    background: $light;
    height: 2em;
    border-radius: 0.3em;
  }

  &.allow-dropdown {
    input,
    input[type='text'],
    input[type='tel'] {
      padding-right: $inputPadding;
      padding-left: $selectedFlagArrowWidth + $inputPadding;
      margin-left: 0;
    }
    .flag-container {
      right: auto;
      left: 0;
      width: 100%;
    }
    .selected-flag {
      width: $selectedFlagArrowWidth;
    }

    // hover state - show flag is clickable
    .flag-container:hover {
      cursor: pointer;
      .selected-flag {
        background-color: $hoverColor;
      }
    }
    // disable hover state when input is disabled
    input[disabled] + .flag-container:hover,
    input[readonly] + .flag-container:hover {
      cursor: default;
      .selected-flag {
        background-color: transparent;
      }
    }

    &.separate-dial-code {
      .selected-flag {
        // now that we have digits in this section, it needs this visual separation
        background-color: $hoverColor;
        // for vertical centering
        display: table;
      }
      .selected-dial-code {
        // for vertical centering
        display: table-cell;
        vertical-align: middle;
        color: $gray;
        font-size: $font-size-sm;
        padding-left: $flagWidth + $flagPadding;
      }

      // .iti-sdc is for Separate Dial Code, with lengths from 2-5 because shortest is "+1", longest is "+1684"
      $charLength: 8px;
      @for $i from 2 through 5 {
        &.iti-sdc-#{$i} {
          input,
          input[type='text'],
          input[type='tel'] {
            padding-left: $selectedFlagArrowDialCodeWidth +
              $inputPadding +
              ($i * $charLength);
          }
          .selected-flag {
            width: $selectedFlagArrowDialCodeWidth + ($i * $charLength);
          }
        }
      }
    }
  }

  // if dropdownContainer option is set, increase z-index to prevent display issues
  &.iti-container {
    position: absolute;
    top: -1000px;
    left: -1000px;
    // higher than default Bootstrap modal z-index of 1050
    z-index: 1060;
    // to keep styling consistent with .flag-container
    padding: $borderWidth;
    &:hover {
      cursor: pointer;
    }
  }
}
