@import 'scss/_breakpoints.scss';
@import 'scss/_index.scss';

.container {
  display: block;
  width: 100%;
  overflow: hidden;
}

$gap: 30px;
$gutter: $gap / 2;

.row {
  display: flex;
  width: 100%;

  &.alignItemsCenter {
    align-items: center;
  }
  &.alignItemsEnd {
    align-items: end;
  }
  &.center {
    justify-content: center;
  }
  &.start {
    justify-content: start;
  }
  &.between {
    justify-content: space-between;
  }
  &.around {
    justify-content: space-around;
  }
  &.end {
    justify-content: flex-end;
  }
  &.alignItemsBaseline {
    align-items: baseline;
  }
  &.withMarginLarge {
    margin-bottom: 2em;
  }
  &.withMarginMedium {
    margin-bottom: 1em;
  }
  &.withMarginUpperSmall {
    margin-bottom: 10px;
  }
  &.withMarginSmall {
    margin-bottom: 0.5em;
  }
  &.withPaddingLarge {
    padding: 2em;
  }
  &.withPaddingMedium {
    padding: 1em;
  }
  &.withPaddingSmall {
    padding: 0.5em;
  }
  &.withSpaceBetween {
    justify-content: space-between;
  }
}

.col {
  padding: 0 $gutter;
  margin-right: $gutter;
  width: 100%;
  margin-bottom: $spacing-lg;
}

@for $i from 1 through 12 {
  .col-#{$i} {
    width: ($i * 100% / 12)- 2%;
    &.withMarginForInput {
      margin-right: 2%;
    }
    &.withMarginRight {
      margin-right: $spacing-md;
    }
  }
}

@media screen and (min-width: $mobile-lg) and (max-width: $tablet) {
  .container {
    padding: 2em;
  }
  @for $i from 1 through 12 {
    .col-#{$i} {
      width: ($i * 100% / 12);
      margin: 0 1em 1em 0;
    }
  }
}

@media screen and (min-width: $mobile) and (max-width: $mobile-lg) {
  .container {
    padding: 2em;
  }
  @for $i from 1 through 12 {
    .col-#{$i} {
      width: 100%;
      margin: 0 1em 1em 0;
    }
  }
}
