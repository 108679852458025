@import 'scss/_index.scss';

.PaymentMethodsSelectLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $font-size-md;

  &.hasSmallText {
    font-size: $font-size-sm;
  }
}

.PaymentMethodsSelectImg {
  width: 27px;
  display: inline-block;
}

.PaymentMethodsSelectOptionWrapper {
  display: flex;
  align-items: center;
  height: 20px;
}

.PaymentMethodsSelectLabelText {
  padding: 0 $spacing-xmd;
  display: block;
  font-size: $font-size-md;
  align-self: stretch;

  &.hasSmallText {
    font-size: $font-size-sm;
  }
}

.PaymentMethodsSelectError {
  color: $red;
  margin: $spacing-md;
  font-size: $font-size-xs;
}
