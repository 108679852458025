@import 'scss/_index';

.wrapper {
  margin: $spacing-md;
}

.DeletePaymentMethodHeader {
  text-align: center;
  margin-bottom: $spacing-lg;
}
