@import 'scss/_index.scss';

.Box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (min-width: $tablet) {
    min-height: 400px;
  }
}

.BoxTextWrapper {
  width: 100%;
  max-width: $page-max-width-md;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $spacing-md $spacing-xmd;
  order: 1;

  @media screen and (min-width: $tablet) {
    padding: 0 $spacing-xmd;

    &.isSecond {
      order: 2;
    }
  }

  @media screen and (min-width: $tablet) {
    width: 50%;
    padding: 0;
    padding-right: $spacing-sm;
  }
}

.BoxHeader {
  font-size: $font-size-xl;
  margin-bottom: $spacing-md;
  color: $sharkGray;
  font-weight: bold;

  @media screen and (min-width: $laptop-lg) {
    padding: 0;
    font-size: $font-size-xxl;
  }
}

.BoxText {
  color: $mediumgray;
  font-size: $font-size-md;
  line-height: 1.5;
}

.BoxImageWrapper {
  order: 1;
  width: 100%;

  @media screen and (min-width: $tablet) {
    width: 50%;
    &.isSecond {
      order: 2;
    }
  }
}

.BoxImg {
  display: block;
  margin: 0 auto;

  &.horizontal {
    width: 720px;
  }

  &.vertical {
    height: 450px;

    @media screen and (min-width: $tablet) {
      height: 573px;
    }
  }
}
