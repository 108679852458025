@import 'scss/_index.scss';

.LoaderIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.LoaderIcon {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border-style: solid;
    border-color: transparent;
    border-top-color: $palegray;
    animation: LoaderIcon__animation 0.7s infinite linear;
    margin: auto;
  }

  &.secondary {
    &::before {
      border-top-color: $white;
    }
  }
}

@keyframes LoaderIcon__animation {
  100% {
    transform: rotate(360deg);
  }
}
