@import 'scss/_index.scss';
.DashboardSidebarItems {
  display: flex;
  flex-direction: row;
  @media screen and (min-width: $tablet) {
    border-right: 1px solid $palegray;
    flex-direction: column;
  }
}

.DashboardSidebarItem {
  display: block;
  border: none;
  background: none;
  margin: $spacing-sm $spacing-md $spacing-lg $spacing-md;
  padding-right: $spacing-md;
  font-size: $font-size-xs;
  color: $gray;
  text-align: right;
  cursor: pointer;
  text-decoration: none;

  @media screen and (max-width: $tablet - 1px) {
    padding-bottom: $spacing-sm;
    box-sizing: border-box;
    border-bottom: 1px solid transparent;
  }
  &:focus {
    outline: none;
  }
  &.isActive {
    font-weight: 600;
    color: $charCoal;
    @media screen and (max-width: $tablet - 1px) {
      border-bottom: 1px solid $palegray;
    }
  }
}
