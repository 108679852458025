@import 'scss/_index.scss';

.CancelBookingInfo {
  width: 16px;
  color: $mousegray;
}

.CancelBookingTaxi {
  width: 50px;
  height: 50px;
  margin-top: calc(#{$spacing-md * 1.25});
  margin-bottom: $spacing-xs;
}

.CancelBookingHeader {
  font-size: $font-size-lg;
  margin: $spacing-md 0;
  color: $lightblack;
}

.SubtitleSpan {
  color: $mousegray;
  font-size: $font-size-sm;
}

.CancelWarningColumn {
  width: initial;
}
