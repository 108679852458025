@import 'scss/_index.scss';

.BookingFormHeader {
  margin-top: $spacing-lg;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  color: $lightblack;
}

.BookingFormLabel {
  display: block;
  font-size: $font-size-xs;
  color: $mousegray;
  font-weight: $font-weight-bold;
}

.BookingFormLabelSelect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $font-size-xs;
}

.BookingFormButton {
  color: $mousegray;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
  &:focus {
    outline: none;
  }
}

.BookingCarSelect {
  width: 50px;
  min-width: 50px;
  margin-right: $spacing-sm;
}

.BookingFormOption {
  padding: 0 $spacing-xs;
  display: block;
  font-size: $font-size-sm;
}

.BookingFormOptionWrapper {
  display: flex;
  align-items: center;
}

.BookingFormCalendar {
  border: 1px solid #ccc;
  padding: $spacing-sm;
  background: $white;
  position: absolute;
  z-index: 5;
  &:focus,
  *:focus {
    outline: none;
  }
}

.BookingMapChevron {
  padding: $spacing-sm;
  width: $spacing-lg;
  height: $spacing-lg;
}

.BookingVehiclePriceLabel {
  margin-left: $spacing-xs;
  font-size: $font-size-sm;
  max-width: 45%;
}

.LoaderIconWrapper {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.BookingFormEmailCCButtonWrapper {
  justify-content: center;
  margin-left: 28px;
}

.BookingFormMapWrapper {
  width: 48%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.RowWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.BookingFormLeftColumn {
  padding-bottom: 2.5em;
  width: 49%;
}

.BookingFormSeparator {
  border: 0;
  height: 1px;
  background-color: $dimmed;
  margin: $spacing-md 0;
}
