@import 'scss/_index.scss';

.Confirmation {
  background: $primary;
  padding: $spacing-sm;
  color: $white;
  border-radius: calc(#{$spacing-md} / 3);
  font-size: $font-size-xs;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ConfirmationIcon {
  width: $spacing-md;
  height: $spacing-md;
  margin: $spacing-sm;
}

.ConfirmationLink {
  text-decoration: underline;
  cursor: pointer;
}
