@import 'scss/_index.scss';

.container {
  display: flex;
  justify-content: flex-end;
  color: $darkgray;
  font-size: $font-size-sm;
}

.container li a {
  display: inline-block;
  padding: $spacing-sm $spacing-md;
  border: 1px solid $dark;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.active {
  background: $primary;
  color: $white;
}
