@import 'scss/_index.scss';

.InvoicingContainer {
  width: 100%;
}

.InvoicingHeader {
  font-size: $font-size-lg;
  color: $lightblack;
  margin-bottom: $spacing-sm;
}

.InvoicingInputWrapper {
  width: 100%;
}

.InvoicingDescription {
  font-size: $font-size-sm;
  color: $mousegray;
  margin-bottom: $spacing-md;

  &.hasBorderBottom {
    padding-bottom: $spacing-lg;
    margin-bottom: $spacing-lg;
    border-bottom: 1px solid $dimmed;
  }
}

.InvoicingLabel {
  display: block;
  font-size: $font-size-xs;
  color: $mousegray;
  padding: $spacing-sm;
}

.InvoicingAgreement {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: $spacing-md;
  color: $mousegray;
  font-size: $font-size-sm;
}

.PendingApprovalMessage {
  font-size: $font-size-md;
  color: $charCoal;
  margin: $spacing-sm auto;
}
