@import 'scss/_index.scss';

.SignUpDisclaimer {
  color: $charCoal;
}
.SignUpDisclaimerLink {
  color: $charCoal;
  font-weight: bold;
}
.InitialWidth {
  width: initial;
}
.PhoneNumberStepWrapper {
  flex-direction: column;
  min-height: 80px;
}
