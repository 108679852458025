@import 'scss/_index.scss';

.Container {
  padding: $spacing-md;
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContentWrapper {
  max-width: 365px;
  text-align: center;
}

.ImageWrapper {
  height: 200px;
}

.Header {
  color: $lightblack;
  font-size: $font-size-lg;
  margin: $spacing-xmd;
  font-weight: bold;
}

.MainText {
  color: $darkgray;
  font-size: $font-size-sm;
  text-align: center;
}
