@import 'scss/_index.scss';

.CookiePolicyContainer {
  background: $colorLightGreyBlue;
  min-height: 100vh;
}

.CookiePolicyLogoWrapper {
  padding: $spacing-md $spacing-lg;
  background-color: $white;
  min-height: 80px;
}

.CookiePolicyLogo {
  width: 56px;
  margin-right: $spacing-lg;
}

.CookiePolicyWrapper {
  padding: $spacing-lg;
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
}

.CookiePolicyContent {
  flex-grow: 1;
}

.CookiePolicyHeader {
  color: $lightblack;
  margin: $spacing-md 0;
  font-size: $font-size-xxl;
  font-weight: $font-weight-bold;
}

.CookiePolicySubheader {
  font-size: $font-size-lg;
  margin-bottom: $spacing-md;
}

.CookiePolicyText {
  color: $mediumgray;
  font-size: $font-size-md;
  margin-top: $spacing-md;
  margin-bottom: $spacing-lg;
  line-height: 1.75;
}

.CookiePolicyBoldText {
  color: $lightblack;
  font-weight: $font-weight-bold;
}

.CookiePolicyListHeader {
  display: block;
  color: $lightblack;
  font-weight: $font-weight-bold;
}

.CookiePolicyListText {
  display: block;
  color: $mediumgray;
  margin: $spacing-md 0;
  line-height: 1.5;
}

.CookiePolicyList {
  margin-bottom: $spacing-xl;
}

.CookiePolicyCopyrightsWrapper {
  margin-bottom: $spacing-lg;
}
