@import 'scss/_index.scss';

.AddUserHeader {
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  margin-bottom: $spacing-md;
  color: $lightblack;
}

.AddUserLabel {
  line-height: 3em;
  color: $mousegray;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
}

.AddUserRadioLabel {
  margin-left: $spacing-sm;
  color: $charCoal;
}

.AddUserButtonsWrapper {
  margin-top: $spacing-xl;
  display: flex;
  justify-content: space-between;
}

.AddUserCSVWrapper {
  font-size: $font-size-sm;
  color: $mousegray;
}

.AddUserDropZoneWrapper {
  width: 100%;
}

.AddUserCSV {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: $primary;
  cursor: pointer;
  background: transparent;
  border: none;

  margin: 0;
  &:focus {
    outline: none;
  }
}

.AddUserParagraph {
  color: $mousegray;
  margin-bottom: $spacing-lg;
}

.AddUserParagraphMedium {
  margin-bottom: $spacing-md;
  color: $lightblack;
}

.AddUserParagraphSmall {
  font-size: $font-size-xs;
  color: $mousegray;
  margin-bottom: $spacing-sm;
}

.AddUserCSVDrop {
  padding: $spacing-md;
  background: rgba(65, 118, 255, 0.02);
  border: 1px solid rgba(65, 118, 255, 0.1);
  margin-bottom: $spacing-lg;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  cursor: pointer;

  &:focus {
    outline: none;
    border: 1px solid $primary;
  }
}

.AddUserCSVBlue {
  color: $primary;
}

.AddUserCSVDropSmall {
  padding: $spacing-md;
  background: $aliceBlue;
  border: 1px solid $lightBlue;
  margin-bottom: $spacing-md;
}

.AddUserIcon {
  margin-right: $spacing-sm;
  width: calc(#{$spacing-md * 0.8});
  height: calc(#{$spacing-md * 0.8});
}

.AddUserResult {
  background: $dimmed;
  overflow-y: scroll;
  height: $spacing-xl;
  padding: $spacing-md;
}

.AddUserDescription {
  font-size: $font-size-xs;
  color: $mousegray;
  margin: $spacing-xmd 0;
}

.AddUserIcon {
  width: $spacing-md;
}

.AddUserInputWrapper {
  max-width: 263px;
}

.AddUserPaymentMethodText {
  color: $lightblack;
  padding: $spacing-sm $spacing-xs;
  font-size: $font-size-sm;
}

.AddUserDownloadTemplateButton {
  color: $primary;
  cursor: pointer;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  font-size: $font-size-sm;

  &:focus {
    outline: none;
  }
}

.AddUserExistingInfo {
  font-size: $font-size-sm;
  color: $mousegray;
}
