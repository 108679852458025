@import 'scss/_index.scss';

.NotFoundPageContainer {
  background-color: $lightgray;
}

.NotFoundPageContentWrapper {
  width: 100%;
  max-width: $page-max-width-md;
  min-height: 100vh;
  padding: $spacing-md;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.NotFoundPageImgWrapper {
  width: 100%;

  @media screen and (min-width: $mobile-lg) {
    width: 60%;
  }
}

.NotFoundPageColumnText {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: $mobile-lg) {
    width: 30%;
    align-items: flex-start;
  }
}

.NotFoundPageHeader {
  font-size: $font-size-xl;
  margin-bottom: $spacing-md;
  color: $lightblack;
  font-weight: bold;

  @media screen and (min-width: $tablet) {
    font-size: calc(#{$font-size-xxl} + 6px);
    margin-bottom: 52px;
  }
}

.NotFoundPageMainText {
  font-size: $font-size-md;
  margin-bottom: $spacing-md;
  color: $lightblack;

  @media screen and (min-width: $tablet) {
    font-size: $font-size-lg;
    margin-bottom: 64px;
  }
}

.NotFoundPageImg {
  width: 100%;
}
