@import 'scss/_index.scss';

.SignUpWrapper {
  position: relative;
  font-size: $font-size-md;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 480px;
  margin: auto;
  padding-bottom: $spacing-lg;
  background: $white;
  box-sizing: border-box;
}

.SignUpContainer {
  overflow: visible;
}
.SignUpContent {
  width: 100%;
}
.SignUpLogo {
  width: 71px;
  height: 31px;
  margin-bottom: $spacing-lg;
}

.Account {
  font-size: $font-size-sm;
  background: $primary;
  margin-top: 1.4em;
}

.BottomSectionWrapper {
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
  margin-top: $spacing-md;
}

.BackButtonWrapper {
  margin-right: $spacing-sm;
  color: $gray;
}

.BackButtonText {
  margin-left: $spacing-xmd;
}

.CopyrightsWrapper {
  position: absolute;
  bottom: 20px;
  left: 0;

  @media screen and (min-width: $tablet) {
    width: 478px;
  }
}

.SignUpNextButton {
  padding: $spacing-md $spacing-lg;
}
