@import 'scss/_index.scss';

.UseMobileAppTemplateContainer {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: $font-family-primary;
  padding: $spacing-md;
}

.UseMobileAppTemplateLogo {
  width: 100px;
}

.UseMobileAppTemplateHeader {
  margin-top: 50px;
  font-size: $font-size-xl;
  font-weight: $font-weight-medium;
}

.UseMobileAppTemplateText {
  color: $mediumgray;
  font-size: $font-size-sm;
  margin-top: 20px;
  margin-bottom: 50px;
}

.UseMobileAppTemplateLoaderWrapper {
  height: 50px;
  width: 50px;
}
