html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  background: none;
}

.adyen-checkout__input {
  -webkit-transition: border 0.2s ease-out, box-shadow 0.2s ease-out;
  background: transparent !important;
  border: 1px solid #b9c4c9;
  border-radius: 6px;
  caret-color: #06f;
  color: #00112c;
  display: block;
  font-family: inherit;
  font-size: 1em;
  height: 40px;
  outline: none;
  padding: 5px 8px;
  position: relative;
  transition: border 0.2s ease-out, box-shadow 0.2s ease-out;
  width: 100%;
}
