@import 'scss/_index.scss';

.UnstyledButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  font-family: $font-family-primary;
  font-size: $font-size-sm;

  &:focus {
    outline: none;
  }
}
