@import 'scss/_index.scss';

.InputWrapper {
  position: relative;

  &.checkbox {
    min-height: 100px;
  }

  &.withTooltip {
    z-index: 9999;
  }
}

.Input {
  text-overflow: ellipsis;
  box-sizing: border-box;
  border: none;
  height: $input-height;
  width: 100%;
  padding-left: 10px;
  color: $lightblack;
  font-size: $font-size-sm;

  &.transparentCaret {
    caret-color: transparent;
  }
  &::placeholder {
    font-family: $font-family-primary;
  }

  &.bgWhite {
    background: $white;
    border: 1px solid $dark;
    border-radius: $border-radius-basic;
    color: $lightblack;
    height: 38px;
    &.isLarge {
      height: 52px;
    }
    &.withError {
      border: 1px solid $red;
    }
    &::placeholder {
      color: $palegray;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: $charCoal;
      -webkit-box-shadow: 0 0 0px 1000px $white inset;
      box-shadow: 0 0 0px 1000px $white inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  &.big {
    height: 6em;
    width: 6em;
    text-align: center;
    padding: 0;
  }
  &.searchInput {
    padding-right: $spacing-lg;
  }
  &.textSmall {
    font-size: $font-size-sm;
  }
  &.bgPrimary {
    background: $primary;
    border: 1px solid $border-light;
    border-radius: $border-radius-basic;
    color: $white;
    &.withError {
      border: 1px solid $red;
    }
    &::placeholder {
      color: $light;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: $white;
      -webkit-box-shadow: 0 0 0px 1000px $primary inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  &.borderBottom {
    border-bottom: 1px solid $whitesmoke;
    padding-left: $spacing-lg;
    &.withError {
      border-bottom: 1px solid $red;
    }
    &::placeholder {
      color: $darkgray;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: $charCoal;
      -webkit-box-shadow: 0 0 0px 1000px $white inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  &.checkbox {
    width: 16px;
  }

  &.hasDisabledStyles {
    cursor: not-allowed;
    color: $mousegray;
    background-color: $dimmed;
  }
}

.Input:focus {
  outline: none;
}

.Input_outlined:focus {
  outline-color: $white;
  outline-width: thin;
}

.InputError {
  color: $light;
  margin-top: $spacing-sm;
  margin-left: $spacing-xs;
  font-size: $font-size-xs;
  &.errorCanFit {
    position: absolute;
    bottom: -20px;
  }
  @include slidedown;
}
.InputErrorLight {
  color: $red;
  margin-top: $spacing-sm;
  margin-left: $spacing-xs;
  font-size: $font-size-xs;
  &.errorCanFit {
    position: absolute;
    bottom: -20px;
  }
  @include slidedown;
}

.Icon {
  width: $font-size-sm;
  height: $font-size-sm;
  top: $spacing-md * 2.3;
  position: relative;
  z-index: 1;
}

.SearchIcon {
  width: $font-size-sm;
  height: $font-size-sm;
  top: 0.7 * $spacing-md;
  left: $spacing-md;
  position: absolute;
  z-index: 1;
}

.TimesIcon {
  width: $font-size-sm;
  border-radius: $spacing-xl;
  top: 0.7 * $spacing-md;
  right: $spacing-md;
  position: absolute;
  z-index: 1;
}

input[type='radio'] {
  display: none;
}

input[type='radio'] + label:before {
  display: inline-block;
  width: $spacing-md;
  height: $spacing-md;
  content: '';
  background: url('../../assets/icons/circle.png');
  background-size: $spacing-md;
  background-repeat: no-repeat;
}

input[type='radio']:checked + label:before {
  display: inline-block;
  content: '';
  background: url('../../assets/icons/check-circle.png');
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: center;
}

.ClearInputIcon {
  cursor: pointer;
}

.InputCheckboxError {
  min-width: 220px;
  position: absolute;
  bottom: 0;
  font-size: $font-size-xs;
  &.errorCanFit {
    position: absolute;
    bottom: -20px;
  }
  @include slidedown;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
