@import 'scss/_index.scss';

.Circle {
  border: calc(#{$spacing-sm} * 1.3) solid;
  background: $white;
  width: $spacing-lg;
  min-width: $spacing-lg;
  height: $spacing-lg;
  min-height: $spacing-lg;
  margin-right: $spacing-md;
  border-radius: 50%;
  &.green {
    border-color: $green;
  }
  &.orange {
    border-color: $orange;
  }
}
