@import 'scss/_index.scss';

.AddCardContainer {
  width: 100%;
}

.AddCardWrapper {
  font-size: $font-size-md;
  width: 100%;
  display: flex;
  justify-content: center;
  background: $primary;
  box-sizing: border-box;
  background: $white;
}

.AddCardLabel {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: $spacing-lg;
  margin-bottom: $spacing-lg;
  font-size: $font-size-lg;
  color: $lightblack;
  font-weight: $font-weight-bold;
}

.AddCardZipcode {
  width: 100%;
}

.AddCardZipcodeLabel {
  font-size: $font-size-xs;
  color: $gray;
  margin-bottom: calc(#{$spacing-sm} / 1.5);
  display: block;
}

// styling for custom adjusted to other input styles in add card form
.AddCardZipcodeInputWrapper {
  input {
    border: 1px solid $dark;
    border-radius: 6px;
    height: 40px;
  }
}

.AddCardSecured {
  color: $light;
  margin-bottom: $spacing-lg;
}

.AddCardButtonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-lg;
}

.AddCardButtonWrapper {
  margin-top: $spacing-lg;
  margin-left: $spacing-xs;
  max-width: 210px;
  width: 49%;

  &.isPrimary {
    margin-left: 0;

    button {
      text-align: left;
      color: $mousegray;
    }
  }
}

.AddCardSpacer {
  margin-bottom: $spacing-md;
}

.AddCardContentWrapper {
  min-height: 510px;
  display: flex;
  flex-direction: column;
}

.AddCardInputsWrapper {
  flex-grow: 1;
}
