@import 'scss/_index.scss';

.adyen-checkout__label {
  .adyen-checkout__label__text {
    color: $gray;
    font-size: $font-size-xs;
    padding-bottom: 10px;
  }
  .adyen-checkout__input:hover:not(.adyen-checkout__input--error) {
    border: 1px solid $dark;
  }
  .adyen-checkout__input.adyen-checkout__input--error:hover {
    border: 1px solid $red;
  }
  .adyen-checkout__input {
    border-color: $dark;
    border-width: 1px;
  }
  .adyen-checkout__input--error {
    border-color: $red;
  }
  .adyen-checkout__error-text {
    color: $red;
  }
}

.adyen-checkout__field {
  .adyen-checkout__label--focused .adyen-checkout__label__text {
    color: rgba(0, 0, 0, 0.6);
  }
}
