@import 'scss/_index.scss';

.DeleteUserHeader {
  text-align: center;
  color: $lightblack;
  font-size: $font-size-sm;
  display: block;

  &.hasCustomTextStyling {
    font-weight: $font-weight-bold;
    font-size: $font-size-lg;
  }
}

.DeleteUserHeaderWrapper {
  color: $lightblack;
}

.DeleteUsersHeaderText {
  display: inline-block;
  font-size: $font-size-lg;
  text-align: center;
  width: 100%;

  &.hasCustomTextStyling {
    margin-bottom: $spacing-md;
    font-weight: $font-weight-bold;
    padding: 0 $spacing-lg;
  }
}

.DeleteUserHeaderDisclaimer {
  font-size: $font-size-sm;
  color: $mousegray;
}

.DeleteUserIcon {
  padding: 0;
  margin-right: $spacing-md;
  width: $font-size-xs;
  height: $font-size-xs;
}
