// color palette
$lightblack: rgba(0, 0, 0, 0.8);
$gray: rgba(0, 0, 0, 0.6);
$darkgray: rgba(0, 0, 0, 0.5);
$mousegray: rgba(0, 0, 0, 0.4);
$palegray: rgba(0, 0, 0, 0.2);
$dark: rgba(0, 0, 0, 0.1);
$solidDark: #e5e5e5;
$dimmed: rgba(0, 0, 0, 0.05);
$shadedgray: rgba(0, 0, 0, 0.03); //TODO - decrease  shades of gray
$mediumgray: #878787;
$lightgray: #eff4fa;
$primary: #4176ff;
$primaryDarken: #345ecc;
$colorPrimaryLight: rgba(65, 119, 255, 0.02);
$colorLightGreyBlue: rgba(239, 244, 250, 0.9);
$light: rgba(255, 255, 255, 0.4);
$lighter: rgba(255, 255, 255, 0.9);
$aliceBlue: #f7fafd;
$lightBlue: #cceeff;

$charCoal: #000;
$sharkGray: #222326;
$white: #fff;
$whitesmoke: #dcdcdc;
$red: red;
$melonRed: #e96e6e;
$pink: #eb008d;
$green: #3dbca3;
$orange: #fb9154;

// font family
$font-family-primary: 'Lato', sans-serif;
$font-family-secondary: 'Nunito Sans', sans-serif;

// font sizes
$font-size-xxxl: 48px;
$font-size-xxl: 36px;
$font-size-xl: 24px;
$font-size-lg: 20px;
$font-size-md: 16px;
$font-size-sm: 14px;
$font-size-xs: 12px;

// font weights
$font-weight-bold: 600;
$font-weight-medium: 500;

// borders
$border-light: $light;
$border-radius-button: 6px;
$border-radius-basic: 4px;
$border-radius-small: 2px;
$border-radius-medium: 5px;
$border-radius-large: 10px;

$input-height: 52px;

// spacing
$spacing-xl: 4em;
$spacing-lg: 2em;
$spacing-md: 1em;
$spacing-xmd: 0.75em;
$spacing-sm: 0.5em;
$spacing-xs: 0.25em;

// animations
$transition-time-basic: 0.3s;

// content wrappers
$page-max-width-lg: 1440px;
$page-max-width-md: 1160px;

// slidedown animation
$ANIMATION_DURATION: 0.2s;
$ANIMATION_TIMING: ease-out;

@mixin slidedown {
  animation-name: slidedown;
  animation-duration: $ANIMATION_DURATION;
  animation-timing-function: $ANIMATION_TIMING;

  @keyframes slidedown {
    0% {
      transform: translateY(-50%);
    }

    100% {
      transform: translateY(0%);
    }
  }
}
